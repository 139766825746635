
const OutsideContact = () => {
    return ( 
        <>
            <div className="p-5 vh-100 " style={{textAlign:"left", backgroundColor: "rgba(154, 53, 204, 0.09)",color: "rgb(38, 52, 180)"}}>
                <h4>Contact Details</h4>
                <hr />
                <p className="mb-4"><h4>Email :</h4> vss122017@gmail.com</p>
                <p className="mb-4"> <h4>Contact :</h4> +91 9901823897</p>
                <h4> Office Address </h4>
                <p>
                Satellite complex,  <br />
                Koppikar road,  <br />
                Hubli - 580029
                </p>
            </div>
        </>
     );
}
 
export default OutsideContact;